<template>
  <article class="partner-page">
    <h1 class="sr-only">Electrify Canada Etron Page</h1>
    <Hero heading="Power the full potential of your Audi e-tron" headlineLevel="h2" :subheading="true">
      <template #subheading> Enjoy DC Fast Charging for your Audi e-tron </template>
      <template #image>
        <img :src="require('@/assets/images/Etron/header.jpg')" alt="" />
      </template>
    </Hero>

    <TwoColumnContentBlock heading="Take Charge" headlineLevel="h3">
      <p>
        Audi e-tron<sup>&reg;</sup> vehicles are fully equipped to take advantage of 150-350 kW DC fast chargers from
        Electrify Canada. Please refer to the chart below to determine which Audi e-tron charging plan you are eligible
        for.
      </p>
      <PartnerDropdown id="etron-select" v-model="modelChosen" :options="etronDropdownOptions">
        <template #label>
          <div>
            <label for="etron-select">Electrify Canada Audi e-tron Charging Plans </label>
          </div>
        </template>
      </PartnerDropdown>
      <p><strong> Audi Charging Program Includes: </strong></p>
      <p>
        1,400 kWh of DC Fast Charging Sessions, Available for Four Years from Vehicle Purchase
        <reference-link num="1" v-if="modelYear === '2025'">
          Available on new model year 2025 Audi e-tron vehicles. 1,400 kWh of DC fast charging sessions, available for
          four years from vehicle purchase. Non-transferable. Not available for commercial use such as ridesharing.
          Requires myAudi account, enrollment in the Electrify Canada charging plan, and acceptance of Terms and
          Conditions. Electrify Canada’s Terms of Use also apply. After user-initiated charge session stops or vehicle
          reaches full charge or charging otherwise stops, and 10 minute grace period, idle fees will apply and user is
          responsible.
        </reference-link>
        <reference-link num="1" v-else
          >Available on new model year 2023 and 2024 Audi e-tron vehicles. 1,400 kWh of DC fast charging sessions,
          available for four years from vehicle purchase. Non-transferable. Not available for commercial use such as
          ridesharing. From February 15, 2024, requires myAudi account, enrollment in the Electrify Canada charging
          plan, and acceptance of Terms and Conditions. Electrify Canada’s Terms of Use also apply. After user-initiated
          charge session stops or vehicle reaches full charge or charging otherwise stops, and 10 minute grace period,
          idle fees will apply and user is responsible.</reference-link
        >
      </p>
      <p><strong> Electrify Canada DC Charging Network </strong></p>
      <p>
        Enjoy the speed of Electrify Canada’s high-powered network! With charging that’s both fast and convenient,
        you’ll be back on the road in no time to enjoy the breathtaking performance of your Audi e-tron.
      </p>
      <p>
        Scroll down to see steps on how to enroll in the app and take full advantage of the Audi e-tron charging plan at
        the Electrify Canada network of chargers.
      </p>
      <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Go With Freedom" headlineLevel="h3" :secondaryBG="false">
      <p>
        Get on the road with the benefits included in your Audi charging plan on Electrify Canada’s DC Fast Charging
        network.
      </p>
      <p>
        Charging is simple. Just follow these
        <span class="wrap-last-two-words"
          >steps: <reference-link num="2">Charging steps are subject to change.</reference-link></span
        >
      </p>
      <ul>
        <li>
          <strong>Step 1:</strong> Locate an Electrify Canada charging station using the myAudi App
          <reference-link num="3"
            >Message and data rates apply. Always pay careful attention to the road, and do not drive while distracted.
            Charging steps are subject to change.</reference-link
          >
          or your Audi vehicle's MMI touchscreen.<reference-link num="4"
            >Always pay careful attention to the road, and do not drive while distracted. See Owner’s Manual for further
            details, and important limitations.</reference-link
          >
        </li>
        <li><strong>Step 2:</strong> Plug in your Audi vehicle.</li>
        <li><strong>Step 3:</strong> Open the myAudi App and enter the charging station ID to start your session.</li>
        <li><strong>Step 4:</strong> The myAudi App will notify you when your charge is complete.</li>
      </ul>
      <p>
        Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call 1-833-231-3226 to
        contact Electrify Canada Customer Assistance, available 24/7.
      </p>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import ReferenceLink from '@/components/ReferenceLink.vue';
import PartnerDropdown from '@/components/PartnerDropdown.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  components: {
    ReferenceLink,
    PartnerDropdown,
    Hero,
    TwoColumnContentBlock,
  },
  metaInfo: {
    title: 'Electrify Canada Audi e-tron Charging Program ',
    meta: [
      {
        name: 'description',
        content:
          'MY 2020-2024 Electrify Canada VW Audi e-tron Charging Plan program description and App sign-up information.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/e-tron/' }],
  },
  data() {
    return {
      etronDropdownOptions: [
        { text: 'Select...', value: null },
        { text: '2025 Audi Q4 e-tron', value: '19' },
        { text: '2025 Audi Q4 Sportback e-tron', value: '18' },
        { text: '2025 Audi Q6 e-tron / SQ6 e-tron', value: '17' },
        { text: '2025 Audi Q6 Sportback e-tron / SQ6 Sportback e-tron', value: '16' },
        { text: '2025 Audi Q8 e-tron / SQ8 e-tron', value: '15' },
        { text: '2025 Audi Q8 Sportback e-tron / SQ8 Sportback e-tron', value: '14' },
        { text: '2025 Audi S e-tron GT / RS e-tron GT', value: '13' },
        { text: '2024 Audi Q4 Sportback e-tron', value: '12' },
        { text: '2024 Audi Q4 e-tron', value: '11' },
        { text: '2024 Audi RS e-tron GT', value: '10' },
        { text: '2024 Audi e-tron GT', value: '9' },
        { text: '2024 Audi Q8 Sportback e-tron / Audi SQ8 Sportback e-tron', value: '8' },
        { text: '2024 Audi Q8 e-tron / Audi SQ8 e-tron', value: '7' },
        { text: '2023 Audi Q4 Sportback e-tron', value: '6' },
        { text: '2023 Audi Q4 e-tron', value: '5' },
        { text: '2023 Audi RS e-tron GT', value: '4' },
        { text: '2023 Audi e-tron GT', value: '3' },
        { text: '2023 Audi e-tron Sportback', value: '2' },
        { text: '2023 Audi e-tron', value: '1' },
      ],
      modelChosen: null,
    };
  },
  computed: {
    modelYearAndName() {
      return this.etronDropdownOptions.find((option) => option.value === this.modelChosen)?.text;
    },
    modelYear() {
      if (this.modelChosen === null) {
        return '2025';
      }
      const n = this.modelYearAndName.split(' ');
      return n[0];
    },
  },
};
</script>
